<template>
  <div class="basicBox">
    <div class="pageName">
      <p>价格颜色</p>
      <el-color-picker v-model="basic_settings.price_color"></el-color-picker>
      <el-button type="primary" @click="resetData('price_color')">重置</el-button>
    </div>
    <div class="pageName">
      <p>加购物车</p>
      <el-radio-group v-model="basic_settings.cart_style">
        <el-radio label="0">样式一</el-radio>
        <el-radio label="1">样式二</el-radio>
      </el-radio-group>
    </div>
    <div class="pageName">
      <p>加购颜色</p>
      <el-color-picker v-model="basic_settings.cart_color"></el-color-picker>
      <el-button type="primary" @click="resetData('cart_color')">重置</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['basic_settings'],
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
      resetData(attr){
          this.$emit('reset-data',{
              data:'basic_settings',
              attr
          })
      }
  },
}
</script>

<style lang="less" scoped>
.basicBox {
  padding: 30px;
  .pageName {
    display: flex;
    margin-bottom: 20px;

    p {
      width: 120px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    .el-input {
      max-width: 300px;
    }
    .el-radio-group {
      height: 40px;
      display: flex;
      align-items: center;
    }
    .el-checkbox-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .el-checkbox {
        margin: 0 10px 10px 0;
      }
    }
    .el-color-picker {
      width: 200px;
      & /deep/ .el-color-picker__trigger {
        width: 100%;
      }
    }
  }
}
</style>
